var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.isButton ? 'Button' : 'div',{tag:"div",staticClass:"c_lp_icon_text",class:[
    'c_lp_icon_text--' + _vm.bgColor,
    _vm.borderShadow == 'border' ? 'c_lp_icon_text--border' : '',
    _vm.borderShadow == 'dropshadow' ? 'c_lp_icon_text--dropshadow' : '',
  ],attrs:{"unstyle":_vm.isButton,"reset":_vm.isButton,"new-window":_vm.isButton && _vm.parseLinkField(_vm.linkField, 'superTable').newWindow
      ? _vm.parseLinkField(_vm.linkField, 'superTable').newWindow
      : null}},[_c('div',{staticClass:"c_lp_icon_text__icon"},[_c('img',{attrs:{"src":_vm.iconSrc,"alt":_vm.iconAlt}})]),_vm._v(" "),_c('h5',{staticClass:"c_header c_header--4 c_text--graphik_semibold c_lp_icon_text__header"},[_c('span',{staticClass:"c_lp_icon_text__header__text",domProps:{"innerHTML":_vm._s(_vm.header)}})]),_vm._v(" "),(_vm.text)?_c('p',{staticClass:"c_text c_lp_icon_text__text",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),_vm._v(" "),(_vm.parseLinkField(_vm.linkField, 'superTable').valid)?_c('LinkField',{staticClass:"c_lp_icon_text__link c_text--graphik_semibold",attrs:{"super-table-field":_vm.linkField,"button-attrs":{ unstyle: true, reset: true }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }