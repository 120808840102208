<template>
  <div
    :is="isButton ? 'Button' : 'div'"
    class="c_lp_icon_text"
    :class="[
      'c_lp_icon_text--' + bgColor,
      borderShadow == 'border' ? 'c_lp_icon_text--border' : '',
      borderShadow == 'dropshadow' ? 'c_lp_icon_text--dropshadow' : '',
    ]"
    :unstyle="isButton"
    :reset="isButton"
    :new-window="
      isButton && parseLinkField(linkField, 'superTable').newWindow
        ? parseLinkField(linkField, 'superTable').newWindow
        : null
    "
  >
    <div class="c_lp_icon_text__icon">
      <img :src="iconSrc" :alt="iconAlt" />
    </div>
    <h5 class="c_header c_header--4 c_text--graphik_semibold c_lp_icon_text__header">
      <span class="c_lp_icon_text__header__text" v-html="header"> </span>
    </h5>
    <p class="c_text c_lp_icon_text__text" v-if="text" v-html="text"></p>
    <LinkField
      v-if="parseLinkField(linkField, 'superTable').valid"
      class="c_lp_icon_text__link c_text--graphik_semibold"
      :super-table-field="linkField"
      :button-attrs="{ unstyle: true, reset: true }"
    />
  </div>
</template>

<script>
import { parseLinkField } from 'JS/global.js';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import LinkField from 'Components/link_field/LinkField.vue';

export default {
  components: {
    Button,
    IconSVG,
    LinkField,
  },
  data() {
    return {
      isButton: !!(
        !parseLinkField(this.linkField, 'superTable').labelText && parseLinkField(this.linkField, 'superTable').href
      ),
    };
  },
  props: {
    bgColor: {
      type: String,
      default: 'white',
    },
    borderShadow: {
      type: String,
    },
    linkField: {
      type: Object,
    },
    header: {
      type: String,
    },
    iconAlt: {
      type: String,
    },
    iconSrc: {
      type: String,
      required: true,
    },
    text: String,
  },
  methods: {
    parseLinkField,
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_lp_icon_text {
  $self: &;
  --lp-icon-text--padding: #{px_to_rem(34)} #{px_to_rem(20)};
  --lp-icon-text--icon: #{px_to_rem(55)};
  --lp-icon-text--icon-height: var(--lp-icon-text--icon);
  --lp-icon-text--icon-width: var(--lp-icon-text--icon);
  --lp-icon-text--header--font-size: inherit;
  --lp-icon-text--header--margin-bottom: #{px_to_rem(15)};
  --lp-icon-text--link-color: #{$color_red};
  --lp-icon-text--link-color--hover: #{$color_black};
  --lp-icon-text--border-radius: var(--border-radius);
  text-align: center;
  padding: var(--lp-icon-text--padding);
  border-radius: var(--lp-icon-text--border-radius);

  @at-root #{$self}--white {
    background: $color_white;
  }
  @at-root #{$self}--yellow {
    background: $color_yellow;
  }

  @at-root #{$self}--border {
    border: 1px solid $color_gray_235;
  }
  @at-root #{$self}--dropshadow {
    box-shadow: 0 px_to_rem(5) px_to_rem(6) rgba($color_black_raw, 0.16);
  }

  @at-root #{$self}__icon {
    height: var(--lp-icon-text--icon-height);
    width: var(--lp-icon-text--icon-width);
    margin: 0 auto;
    margin-bottom: px_to_rem(18);
    svg {
      max-width: 100%;
      max-height: 100%;
      vertical-align: top;
    }
  }
  @at-root #{$self}__header {
    margin-bottom: var(--lp-icon-text--header--margin-bottom);
    @at-root #{&}__text {
      font-size: var(--lp-icon-text--header--font-size);
    }
  }
  @at-root #{$self}__link {
    color: var(--lp-icon-text--link-color);
    text-decoration: none;
    padding-top: px_to_rem(25);
    margin-top: auto;
    display: inline-block;
    &:hover,
    &:focus {
      color: var(--lp-icon-text--link-color--hover);
    }
  }
}

button.c_lp_icon_text {
  &:hover,
  &:focus {
    background: $color_gray_235;
  }
}
</style>
